<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="6">
        <i-input v-model="query.keyword" size="small" placeholder="关键字：业态名称"></i-input>
      </i-col>
      <i-col span="2">
        <Button type="primary" icon="ios-search" size="small" @click="handleSearch">搜索</Button>
      </i-col>
      <i-col span="16" class="text-right">
        <i-button type="success" size="small" @click="handleAddIndustry">租户业态录入</i-button>
      </i-col>
    </Row>
    <Table stripe size="small" :data="list" :columns="tableColumns" :loading="tableLoading">
    </Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer
        :current="query.pageNumber" @on-change="handleChangePage" :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'></Page>
    </div>
    <Modal v-model="addModal" footer-hide>
      <p slot="header" class="text-center">租户业态录入</p>
      <div>
        <Form ref="formData" :model="formData" :rules="formRules">
          <FormItem label="业态名称" prop="name">
            <i-input size="small" v-model="formData.name" placeholder="填写业态名称" />
          </FormItem>
          <!-- <FormItem label="是否可用">
            <i-switch v-model="formData.enabled">
            </i-switch>
          </FormItem> -->
          <FormItem class="m-t-20 text-right">
            <Button type="text" class="m-r-5" @click="handleCancel">取消</Button>
            <Button type="primary" :loading="submitLoading" @click="handleSubmit">确认录入</Button>
          </FormItem>
        </Form>

      </div>
    </Modal>
  </div>
</template>
<script>
import { page, add, update, deleteIndustry } from '@/api/os/industry'
const getFormData = () => {
  return {
    id: undefined,
    name: '',
    enabled: true,
    sequence: undefined,
    publisherId: undefined,
    type: 3
  }
}
export default {
  data () {
    return {
      userInfo: this.$store.getters.token.userInfo,
      pagesizeSetting: [15, 50, 100, 200, 500],
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: null,
        type: 3,
        keyword: ''
      },
      list: [],
      total: 0,
      // tableData: {
      //   list: [],
      //   totalRow: 0
      // },
      tableColumns: [
        { title: '一类业态', key: 'name' },
        // {
        //   title: '状态',
        //   key: 'enabled',
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('span', params.row.enabled ? '可用' : '不可用')
        //   }
        // },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const html = [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleModify(params.row)
                  }
                }
              }, '编辑'),
              h('a', {
                style: { color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.handleDel(params.row)
                  }
                }
              }, '删除')
            ]
            return html
          }
        }
      ],
      tableLoading: false,
      addModal: false,
      formData: getFormData(),
      formRules: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      submitLoading: false,
      modifyType: 1 // 1:新增，2：编辑
    }
  },
  created () {
    this.query.publisherId = this.userInfo.publisherId
    this.getTableData()
  },
  methods: {
    getTableData () {
      this.tableLoading = true
      page(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    changePageSize (pageSize) {
      this.query.pageNumber = 1
      this.query.pageSize = pageSize
      this.getTableData()
    },
    handleAddIndustry () {
      this.formData.publisherId = this.userInfo.publisherId
      this.addModal = true
    },
    handleModify (params) {
      this.modifyType = 2
      // this.formData = Object.assign(this.formData, params)
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = params[key]
      })
      this.addModal = true
    },
    handleCancel () {
      this.formData = getFormData()
      this.addModal = false
    },
    handleDel (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该项？',
        onOk: () => {
          deleteIndustry({ industryId: params.id }).then(res => {
            if (res) {
              this.$Notice.success({ title: '操作提示', desc: '操作成功' })
              this.getTableData()
            }
          })
        }
      })
    },
    handleSubmit () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.modifyType === 1) {
            add(this.formData).then(res => {
              if (res && !res.errcode) {
                this.$Notice.success({ title: '操作提示', desc: '操作成功' })
                this.getTableData()
                this.$refs.formData.resetFields()
                this.formData = getFormData()
                this.addModal = false
              }
            }).finally(() => { this.submitLoading = false })
          } else {
            update(this.formData).then(res => {
              if (res && !res.errcode) {
                this.$Notice.success({ title: '操作提示', desc: '操作成功' })
                this.getTableData()
                this.$refs.formData.resetFields()
                this.formData = getFormData()
                this.addModal = false
              }
            }).finally(() => { this.submitLoading = false })
          }
        }
      })
    }
  }
}
</script>
